import React, { useState, useEffect, useCallback } from 'react';
import {
  getAllInvoices,
  getExpiringCustomers,
  getAllCustomers,
  getCustomersWithIncreaseNotices,
  getTopPerformingAccounts,
  getCustomerWithLowestGM,
  getCustomerWithHighestGM,
} from '../utils/api';
import { formatAsDollarAmount, truncateDecimals } from '../utils/utils';
import { Link, useOutletContext } from 'react-router-dom';
import CustomerViewModal from './CustomerViewModal';

import { Modal } from 'flowbite';

const DashboardContent = ({ history }) => {
  const { token } = useOutletContext();
  const [allInvoices, setAllInvoices] = useState({});
  const [allCustomers, setAllCustomers] = useState([]);
  const [allExpiringCustomers, setAllExpiringCustomers] = useState([]);
  const [allIncreaseNotices, setAllIncreaseNotices] = useState([]);
  const [topPerformingAccounts, setTopPerformingAccounts] = useState([]);
  const [currentCustomers, setCurrentCustomers] = useState([]);
  const [lowestGMCustomer, setLowestGMCustomer] = useState();
  const [highestGMCustomer, setHighestGMCustomer] = useState();
  // set the modal menu element
  const $targetEl = document.getElementById('view-customer-modal');

  // options with default values
  const options = {
    placement: 'bottom-right',
    backdrop: 'dynamic',
    closable: true,
    onHide: () => {
      console.log('modal is hidden');
    },
    onShow: () => {
      console.log('modal is shown');
    },
    onToggle: () => {
      console.log('modal has been toggled');
    },
  };

  const modal = new Modal($targetEl, options);

  const fetchData = useCallback(async () => {
    const allInvoices = await getAllInvoices(token);
    const allCustomers = await getAllCustomers(token);
    const allExpiringCustomers = await getExpiringCustomers(token);
    const allIncreaseNotices = await getCustomersWithIncreaseNotices(token);
    const topPerformingAccounts = await getTopPerformingAccounts(token);
    const lowestGMCustomer = await getCustomerWithLowestGM(token);
    const highestGMCustomer = await getCustomerWithHighestGM(token);

    console.log('All invoices:', allInvoices);
    console.log('All Customers:', allCustomers);
    console.log('All Expiring:', allExpiringCustomers);
    console.log('All Increase Notices:', allIncreaseNotices);
    console.log('Top performing accounts:', topPerformingAccounts);
    console.log('Lowest GM: ', lowestGMCustomer);
    console.log('Highest GM: ', highestGMCustomer);

    setAllInvoices(allInvoices);
    setAllCustomers(allCustomers);
    setAllExpiringCustomers(allExpiringCustomers);
    setAllIncreaseNotices(allIncreaseNotices);
    setTopPerformingAccounts(topPerformingAccounts);
    setLowestGMCustomer(lowestGMCustomer);
    setHighestGMCustomer(highestGMCustomer);
  }, []);

  // the useEffect is only there to call `fetchData` at the right time
  useEffect(() => {
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [fetchData]);
  return (
    <div className="p-4 sm:ml-64">
      <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
          <div className="flex flex-col items-center justify-center h-36 rounded bg-gray-800">
            <h5 className="text-2xl text-white font-bold pb-2">
              Total Customers
            </h5>
            <p className="text-1xl text-white">{allCustomers.length}</p>
            <div className="w-full flex justify-center mt-3 mb-3">
              <Link to="/home/all-customers">
                <div
                  className="cursor-pointer inline-flex items-center justify-center px-2 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300"
                  type="button"
                >
                  View
                </div>
              </Link>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center h-36 rounded bg-gray-800">
            <p className="text-2xl text-white font-bold pb-2">
              Expiring Contracts
            </p>
            <p className="text-1xl text-white">{allExpiringCustomers.length}</p>
            <div className="w-full flex justify-center mt-3 mb-3">
              <div
                data-modal-target="view-customer-modal"
                data-modal-toggle="view-customer-modal"
                onClick={() => {
                  setCurrentCustomers(allExpiringCustomers);
                  modal.show();
                }}
                className="cursor-pointer inline-flex items-center justify-center px-2 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300"
                type="button"
              >
                View
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center h-36 rounded bg-gray-800">
            <p className="text-2xl text-white font-bold pb-2">
              Increase Notices Due
            </p>
            <p className="text-1xl text-white">{allIncreaseNotices.length}</p>
            <div className="w-full flex justify-center mt-3 mb-3">
              <div
                data-modal-target="view-customer-modal"
                data-modal-toggle="view-customer-modal"
                onClick={() => {
                  setCurrentCustomers(allIncreaseNotices);
                  modal.show();
                }}
                className="cursor-pointer inline-flex items-center justify-center px-2 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300"
                type="button"
              >
                View
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center h-32 rounded bg-gray-800">
            <h5 className="text-2xl text-white font-bold pb-2">
              Current Month Total
            </h5>
            <p className="text-1xl text-white">
              {formatAsDollarAmount(allInvoices.currentMonthTotal)}
            </p>
          </div>
          <div className="flex flex-col items-center justify-center h-32 rounded bg-gray-800">
            <p className="text-2xl text-white font-bold pb-2">
              Current Year Total
            </p>
            <p className="text-1xl text-white">
              {formatAsDollarAmount(allInvoices.currentYearTotal)}
            </p>
          </div>
          <div className="flex flex-col items-center justify-center h-32 rounded bg-gray-800">
            <p className="text-2xl text-white font-bold pb-2">
              Average Gross Margin
            </p>
            <p className="text-1xl text-white">
              {truncateDecimals(allInvoices.averageGMPercent)}%
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center mb-4 rounded bg-gray-800 p-4">
          <p className="text-2xl text-white font-bold pb-2">
            Top Performing Clients
          </p>
          <ul className="w-full divide-y divide-gray-200 dark:divide-gray-700">
            {topPerformingAccounts.map((item, index) => (
              <li className="full-w pt-2 pb-2" key={index}>
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-8 h-8 rounded-full"
                      xmlns="http://www.w3.org/2000/svg"
                      width="64px"
                      height="64px"
                      viewBox="0 0 64 64"
                      version="1.1"
                    >
                      <rect
                        fill="#ddd"
                        cx="32"
                        width="64"
                        height="64"
                        cy="32"
                        r="32"
                      ></rect>
                      <text
                        x="50%"
                        y="50%"
                        alignmentBaseline="middle"
                        textAnchor="middle"
                        fontSize="28"
                        fontWeight="400"
                        dy=".1em"
                        dominantBaseline="middle"
                        fill="#222"
                        style={{
                          color: 'rgb(34, 34, 34)',
                          lineHeight: 1,
                          fontFamily:
                            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                        }}
                      >
                        {item.customerName.charAt(0)}
                      </text>
                    </svg>
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                      {item.customerName}
                    </p>
                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                      {formatAsDollarAmount(item.totalAmount)}
                    </p>
                  </div>
                  <Link
                    onClick={() => {
                      window.location = `/home/customer/${item.customerId}`;
                    }}
                  >
                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white hover:bg-gray-700 p-2 rounded-lg cursor-pointer">
                      View Details
                    </div>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <CustomerViewModal customers={currentCustomers} />
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="flex flex-col items-center justify-center mb-4 rounded bg-gray-800 p-4">
            <p className="text-2xl text-white font-bold pb-2">
              Customer With Highest GM
            </p>
            {highestGMCustomer && (
              <ul className="w-full divide-y divide-gray-200 dark:divide-gray-700">
                <li
                  className="full-w pt-2 pb-2"
                  key={highestGMCustomer.customer._id}
                >
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <svg
                        className="w-8 h-8 rounded-full"
                        xmlns="http://www.w3.org/2000/svg"
                        width="64px"
                        height="64px"
                        viewBox="0 0 64 64"
                        version="1.1"
                      >
                        <rect
                          fill="#ddd"
                          cx="32"
                          width="64"
                          height="64"
                          cy="32"
                          r="32"
                        ></rect>
                        <text
                          x="50%"
                          y="50%"
                          alignmentBaseline="middle"
                          textAnchor="middle"
                          fontSize="28"
                          fontWeight="400"
                          dy=".1em"
                          dominantBaseline="middle"
                          fill="#222"
                          style={{
                            color: 'rgb(34, 34, 34)',
                            lineHeight: 1,
                            fontFamily:
                              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                          }}
                        >
                          {highestGMCustomer.customer.name.charAt(0)}
                        </text>
                      </svg>
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        {highestGMCustomer.customer.name}
                      </p>
                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                        {formatAsDollarAmount(highestGMCustomer.grossMargin)} (
                        {truncateDecimals(highestGMCustomer.grossMarginPercent)}
                        %)
                      </p>
                    </div>
                    <a
                      href={`/home/customer/${highestGMCustomer.customer._id}`}
                    >
                      <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white hover:bg-gray-700 p-2 rounded-lg cursor-pointer">
                        View Details
                      </div>
                    </a>
                  </div>
                </li>
              </ul>
            )}
          </div>
          <div className="flex flex-col items-center justify-center mb-4 rounded bg-gray-800 p-4">
            <p className="text-2xl text-white font-bold pb-2">
              Customer With Lowest GM
            </p>
            {lowestGMCustomer && (
              <ul className="w-full divide-y divide-gray-200 dark:divide-gray-700">
                <li
                  className="full-w pt-2 pb-2"
                  key={lowestGMCustomer.customer._id}
                >
                  <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <svg
                        className="w-8 h-8 rounded-full"
                        xmlns="http://www.w3.org/2000/svg"
                        width="64px"
                        height="64px"
                        viewBox="0 0 64 64"
                        version="1.1"
                      >
                        <rect
                          fill="#ddd"
                          cx="32"
                          width="64"
                          height="64"
                          cy="32"
                          r="32"
                        ></rect>
                        <text
                          x="50%"
                          y="50%"
                          alignmentBaseline="middle"
                          textAnchor="middle"
                          fontSize="28"
                          fontWeight="400"
                          dy=".1em"
                          dominantBaseline="middle"
                          fill="#222"
                          style={{
                            color: 'rgb(34, 34, 34)',
                            lineHeight: 1,
                            fontFamily:
                              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                          }}
                        >
                          {lowestGMCustomer.customer.name.charAt(0)}
                        </text>
                      </svg>
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                        {lowestGMCustomer.customer.name}
                      </p>
                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                        {formatAsDollarAmount(lowestGMCustomer.grossMargin)} (
                        {truncateDecimals(lowestGMCustomer.grossMarginPercent)}
                        %)
                      </p>
                    </div>
                    <a href={`/home/customer/${lowestGMCustomer.customer._id}`}>
                      <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white hover:bg-gray-700 p-2 rounded-lg cursor-pointer">
                        View Details
                      </div>
                    </a>
                  </div>
                </li>
              </ul>
            )}
          </div>
          <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
            <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
          </div>
          <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
            <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
          </div>
        </div>
        <div className="flex items-center justify-center h-48 mb-4 rounded bg-gray-800">
          <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
            <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
          </div>
          <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
            <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
          </div>
          <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
            <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
          </div>
          <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
            <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardContent;
