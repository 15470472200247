const formatAsDollarAmount = (number) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return formatter.format(number);
};

const truncateDecimals = (number) => {
    if (!number) {
        return 0;
    }
    return Math.trunc(number * 100) / 100;
};
export { formatAsDollarAmount, truncateDecimals };