import React from 'react';

const CustomerViewModal = ({ customers }) => {
  return (
    <div
      id="view-customer-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="text-white fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex items-center justify-center"
    >
      <div className="relative rounded-lg shadow bg-gray-700 p-8 max-w-3xl w-full w-[75%]">
        <ul className="w-full divide-y divide-gray-200 dark:divide-gray-700">
          {customers.map((item, index) => (
            <li className="full-w pt-2 pb-2" key={index}>
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  <svg
                    className="w-8 h-8 rounded-full"
                    xmlns="http://www.w3.org/2000/svg"
                    width="64px"
                    height="64px"
                    viewBox="0 0 64 64"
                    version="1.1"
                  >
                    <rect
                      fill="#ddd"
                      cx="32"
                      width="64"
                      height="64"
                      cy="32"
                      r="32"
                    ></rect>
                    <text
                      x="50%"
                      y="50%"
                      alignmentBaseline="middle"
                      textAnchor="middle"
                      fontSize="28"
                      fontWeight="400"
                      dy=".1em"
                      dominantBaseline="middle"
                      fill="#222"
                      style={{
                        color: 'rgb(34, 34, 34)',
                        lineHeight: 1,
                        fontFamily:
                          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                      }}
                    >
                      {item.name.charAt(0)}
                    </text>
                  </svg>
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                    {item.name}
                  </p>
                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                    {item.customer_number}
                  </p>
                </div>
                <a href={`/home/customer/${item._id}`}>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white hover:bg-gray-700 p-2 rounded-lg cursor-pointer">
                    View Details
                  </div>
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CustomerViewModal;
