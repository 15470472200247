import React, { useState, useRef } from 'react';
import { editCustomer } from '../utils/api';

Date.prototype.toDateInputValue = function () {
  var local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};

const EditCustomerModal = ({ customer, token, fetchCustomer }) => {
  const [customerData, setCustomerData] = useState(customer);
  const closeButtonRef = useRef(null);
  const [buttonText, setButtonText] = useState('Update Customer');
  console.log(customerData);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText('Updating..');
    // Perform desired actions with customerData
    console.log(customerData, token, customerData._id, customerData);
    const editedCustomer = await editCustomer(
      token,
      customerData._id,
      customerData
    );
    setButtonText('Success!');

    console.log('Edited customer:', editedCustomer);
    fetchCustomer();
    setTimeout(function () {
      if (closeButtonRef.current) {
        closeButtonRef.current.click();
      }
    }, 1000);
    // Reset form values
  };
  const handleDate = (date) => {
    if (date) {
      return date.split('T')[0];
    } else {
      return new Date().toDateInputValue();
    }
  };
  return (
    <div
      id="edit-customer-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="text-white fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex justify-center items-baseline"
    >
      <div className="relative rounded-lg shadow bg-gray-700 p-8 max-w-3xl w-full w-[75%]">
        {/* Modal content */}
        <button
          ref={closeButtonRef}
          type="button"
          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          data-modal-hide="edit-customer-modal"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close modal</span>
        </button>

        <div className="p-4">
          <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
            <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-16">
              <fieldset className="flex flex-row items-start">
                <legend className="text-2xl xl:text-3xl font-extrabold pb-2">
                  Customer Information {customerData.name}
                </legend>
                <div className="grid grid-cols-2 gap-4 w-full text-black">
                  <div className="flex flex-col items-start">
                    <label htmlFor="name" className="text-lg text-white">
                      Name*
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={customerData.name}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                      required
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="customer_number"
                      className="text-lg text-white"
                    >
                      Customer ID*
                    </label>
                    <input
                      type="text"
                      id="customer_number"
                      name="customer_number"
                      value={customerData.customer_number}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                      required
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label htmlFor="email" className="text-lg text-white">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={customerData.email}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="contact_name"
                      className="text-lg text-white"
                    >
                      Contact Name
                    </label>
                    <input
                      type="text"
                      id="contact_name"
                      name="contact_name"
                      value={customerData.contact_name}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="contact_phone"
                      className="text-lg text-white"
                    >
                      Contact Phone
                    </label>
                    <input
                      type="tel"
                      id="contact_phone"
                      name="contact_phone"
                      value={customerData.contact_phone}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="distance_to_location"
                      className="text-lg text-white"
                    >
                      Distance to Location
                    </label>
                    <input
                      type="number"
                      id="distance_to_location"
                      name="distance_to_location"
                      value={customerData.distance_to_location}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="average_driver_time"
                      className="text-lg text-white"
                    >
                      Average Driver Time
                    </label>
                    <input
                      type="number"
                      id="average_driver_time"
                      name="average_driver_time"
                      value={customerData.average_driver_time}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                </div>
                <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
              </fieldset>
              <fieldset className="flex flex-row items-start">
                <legend className="text-2xl xl:text-3xl font-extrabold pb-2">
                  Contract Information
                </legend>
                <div className="grid grid-cols-2 gap-4 w-full text-black">
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="contract_start"
                      className="text-lg text-white"
                    >
                      Contract Start*
                    </label>
                    <input
                      type="date"
                      id="contract_start"
                      name="contract_start"
                      value={handleDate(customerData.contract_start)}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                      required
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label htmlFor="term_length" className="text-lg text-white">
                      Term Length*
                    </label>
                    <input
                      type="number"
                      id="term_length"
                      name="term_length"
                      value={customerData.term_length}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                      required
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="increase_notice"
                      className="text-lg text-white"
                    >
                      Increase Notice*
                    </label>
                    <input
                      type="date"
                      id="increase_notice"
                      name="increase_notice"
                      value={handleDate(customerData.increase_notice)}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                      required
                    />
                  </div>
                </div>
                <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
              </fieldset>
              <fieldset className="flex flex-row items-start">
                <legend className="text-2xl xl:text-3xl font-extrabold pb-2">
                  Product and Equipment Info
                </legend>
                <div className="grid grid-cols-2 gap-4 w-full text-black">
                  <div className="flex flex-col items-start">
                    <label htmlFor="product" className="text-lg text-white">
                      Product
                    </label>
                    <select
                      id="product"
                      name="product"
                      value={customerData.product}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    >
                      <option value="">Select Product</option>
                      <option value="Argon">Argon</option>
                      <option value="Nitrogen">Nitrogen</option>
                      <option value="Oxygen">Oxygen</option>
                      <option value="Multiple">Multiple</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="estimated_yearly_volume"
                      className="text-lg text-white"
                    >
                      Estimated Yearly Volume
                    </label>
                    <input
                      type="number"
                      id="estimated_yearly_volume"
                      name="estimated_yearly_volume"
                      value={customerData.estimated_yearly_volume}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="equipment_installed"
                      className="text-lg text-white"
                    >
                      Equipment Installed
                    </label>
                    <select
                      id="equipment_installed"
                      name="equipment_installed"
                      value={customerData.equipment_installed}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    >
                      <option value="">Select Equipment</option>
                      <option value="7500L">7500L</option>
                      <option value="5500L">5500L</option>
                      <option value="3000L">3000L</option>
                      <option value="2000L">2000L</option>
                      <option value="1500L">1500L</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="labor_to_install"
                      className="text-lg text-white"
                    >
                      Labor to Install
                    </label>
                    <input
                      type="number"
                      id="labor_to_install"
                      name="labor_to_install"
                      value={customerData.labor_to_install}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label htmlFor="tank_id" className="text-lg text-white">
                      Tank ID
                    </label>
                    <input
                      type="text"
                      id="tank_id"
                      name="tank_id"
                      value={customerData.tank_id}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="tank_monthly_rental_fee"
                      className="text-lg text-white"
                    >
                      Tank Monthly Rental Fee
                    </label>
                    <input
                      type="number"
                      id="tank_monthly_rental_fee"
                      name="tank_monthly_rental_fee"
                      value={customerData.tank_monthly_rental_fee}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="telemetry_id"
                      className="text-lg text-white"
                    >
                      Telemetry ID
                    </label>
                    <input
                      type="text"
                      id="telemetry_id"
                      name="telemetry_id"
                      value={customerData.telemetry_id}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="telemetry_monthly_rental_fee"
                      className="text-lg text-white"
                    >
                      Telemetry Monthly Rental Fee
                    </label>
                    <input
                      type="number"
                      id="telemetry_monthly_rental_fee"
                      name="telemetry_monthly_rental_fee"
                      value={customerData.telemetry_monthly_rental_fee}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="date_installed"
                      className="text-lg text-white"
                    >
                      Date Installed
                    </label>
                    <input
                      type="date"
                      id="date_installed"
                      name="date_installed"
                      value={handleDate(customerData.date_installed)}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="first_fill_date"
                      className="text-lg text-white"
                    >
                      First Fill Date
                    </label>
                    <input
                      type="date"
                      id="first_fill_date"
                      name="first_fill_date"
                      value={handleDate(customerData.first_fill_date)}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset className="flex flex-row items-start">
                <legend className="text-2xl xl:text-3xl font-extrabold pb-2">
                  Investment Info
                </legend>
                <div className="grid grid-cols-2 gap-4 w-full text-black">
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="capex_invested_value"
                      className="text-lg text-white"
                    >
                      Capex Invested Value
                    </label>
                    <input
                      type="number"
                      id="capex_invested_value"
                      name="capex_invested_value"
                      value={customerData.capex_invested_value}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>

                  <div className="flex flex-col items-start">
                    <label htmlFor="roi" className="text-lg text-white">
                      ROI (Return on Investment)
                    </label>
                    <input
                      type="number"
                      id="roi"
                      name="roi"
                      value={customerData.roi}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label htmlFor="roi_date" className="text-lg text-white">
                      ROI Date
                    </label>
                    <input
                      type="date"
                      id="roi_date"
                      name="roi_date"
                      value={handleDate(customerData.roi_date)}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <label
                      htmlFor="roi_at_contract_end"
                      className="text-lg text-white"
                    >
                      ROI at Contract End
                    </label>
                    <input
                      type="number"
                      id="roi_at_contract_end"
                      name="roi_at_contract_end"
                      value={customerData.roi_at_contract_end}
                      onChange={handleChange}
                      className="w-full border-2 border-gray-300 rounded p-2"
                    />
                  </div>
                </div>
              </fieldset>
              <div className="">
                <button
                  type="submit"
                  className="bg-gray-800 text-white  py-2 px-4 rounded"
                >
                  {buttonText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCustomerModal;
