import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getAllCustomers = async(token) => {
    try {
        const response = await axios.get(`${API_URL}/customers`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};
const getAllInvoices = async(token) => {
    try {
        const response = await axios.get(`${API_URL}/invoices`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

const getExpiringCustomers = async(token) => {
    try {
        const response = await axios.get(`${API_URL}/expiring-customers`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

const getTopPerformingAccounts = async(token) => {
    try {
        const response = await axios.get(`${API_URL}/top-performing-accounts`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

const getCustomersWithIncreaseNotices = async(token) => {
    try {
        const response = await axios.get(`${API_URL}/increase-notices`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};
const getCustomerWithLowestGM = async(token) => {
    try {
        const response = await axios.get(`${API_URL}/customer-lowest-gm`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};
const getCustomerWithHighestGM = async(token) => {
    try {
        const response = await axios.get(`${API_URL}/customer-highest-gm`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};
const getCustomerById = async(token, customerId) => {
    try {
        const response = await axios.get(`${API_URL}/customer/${customerId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

const addCustomer = async(token, customerData) => {
    try {
        const response = await axios.post(
            `${API_URL}/create-customer`,
            customerData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

const editCustomer = async(token, customerId, customerData) => {
    console.log(customerId);
    try {
        const response = await axios.put(
            `${API_URL}/update-customer/${customerId}`,
            customerData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

const addInvoice = async(token, customerId, invoiceData) => {
    try {
        const response = await axios.post(
            `${API_URL}/invoices`, { customerId: customerId, invoiceData: invoiceData }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

export {
    getAllCustomers,
    getCustomerById,
    addCustomer,
    addInvoice,
    editCustomer,
    getAllInvoices,
    getExpiringCustomers,
    getCustomersWithIncreaseNotices,
    getTopPerformingAccounts,
    getCustomerWithLowestGM,
    getCustomerWithHighestGM,
};