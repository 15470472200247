import React, { Component } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { isAuth } from '../helpers/auth';

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       isAuth() ? (
//         <Component {...props} />
//       ) : (
//         <Navigate
//           to={{
//             pathname: '/login',
//             state: { from: props.location },
//           }}
//         />
//       )
//     }
//   ></Route>
// );

// export default PrivateRoute;

const PrivateRoute = ({ children }) => {
  const auth = isAuth();
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
