import React, { useState, useRef } from 'react';
import { addInvoice } from '../utils/api';
import { useOutletContext } from 'react-router-dom';

const AddInvoiceModal = ({ customerId, customerName, fetchCustomer }) => {
  const { token } = useOutletContext();
  const closeButtonRef = useRef(null);
  const [selectedLineItems, setSelectedLineItems] = useState([]);
  const [buttonActionText, setButtonActionText] = useState('Submit');
  const [formData, setFormData] = useState({
    customer: '',
    month: '',
    year: '',
    invoiceNumber: '',
    deliveryCharges: '',
    deliveryChargesGM: '',
    deliveryChargesGMPercent: '',
    microbulkRent: '',
    microbulkRentGM: '',
    microbulkRentGMPercent: '',
    installLabor: '',
    installLaborGM: '',
    installLaborGMPercent: '',
    installParts: '',
    installPartsGM: '',
    installPartsGMPercent: '',
    telemetryRent: '',
    telemetryRentGM: '',
    telemetryRentGMPercent: '',
    microbulkFill: '',
    microbulkFillGM: '',
    microbulkFillGMPercent: '',
    cylinderMaintenanceCharge: '',
    cylinderMaintenanceChargeGM: '',
    cylinderMaintenanceChargeGMPercent: '',
    hazmatCharge: '',
    hazmatChargeGM: '',
    hazmatChargeGMPercent: '',
    miscellaneousCharge: '',
    miscellaneousChargeGM: '',
    miscellaneousChargeGMPercent: '',
    total: '',
    totalGM: '',
    totalGMPercent: '',
  });

  const lineItems = [
    'Delivery',
    'Microbulk Rental',
    'Install Labor',
    'Install Parts',
    'Telemetry Rental',
    'Microbulk Fill',
    'Cylinder Maintenance',
    'Hazmat Charge',
    'Miscellaneous',
  ];
  const months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ];

  const handleLineItemChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      // Add the selected line item to the list
      setSelectedLineItems((prevSelectedItems) => [
        ...prevSelectedItems,
        value,
      ]);
    } else {
      // Remove the deselected line item from the list
      setSelectedLineItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== value)
      );
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    setButtonActionText('Submitting');
    e.preventDefault();
    // Handle form submission
    console.log(formData);
    formData.deliveryChargesGMPercent = calculateGMPercent('deliveryCharges');
    formData.microbulkRentGMPercent = calculateGMPercent('microbulkRent');
    formData.installLaborGMPercent = calculateGMPercent('installLabor');
    formData.installPartsGMPercent = calculateGMPercent('installParts');
    formData.telemetryRentGMPercent = calculateGMPercent('telemetryRent');
    formData.microbulkFillGMPercent = calculateGMPercent('microbulkFill');
    formData.cylinderMaintenanceChargeGMPercent = calculateGMPercent(
      'cylinderMaintenanceCharge'
    );
    formData.hazmatChargeGMPercent = calculateGMPercent('hazmatCharge');
    formData.miscellaneousChargeGMPercent = calculateGMPercent(
      'miscellaneousCharge'
    );
    formData.total = calculateTotal();
    formData.totalGM = calculateTotalGM();
    formData.totalGMPercent = calculateTotalGMPercentage();
    formData.customer = customerId;
    console.log(formData);
    const addedInvoice = await addInvoice(token, customerId, formData);
    setButtonActionText('Success!');
    fetchCustomer();
    setTimeout(function () {
      if (closeButtonRef.current) {
        closeButtonRef.current.click();
      }
      setFormData({
        customer: '',
        month: '',
        year: '',
        invoiceNumber: '',
        deliveryCharges: '',
        deliveryChargesGM: '',
        deliveryChargesGMPercent: '',
        microbulkRent: '',
        microbulkRentGM: '',
        microbulkRentGMPercent: '',
        installLabor: '',
        installLaborGM: '',
        installLaborGMPercent: '',
        installParts: '',
        installPartsGM: '',
        installPartsGMPercent: '',
        telemetryRent: '',
        telemetryRentGM: '',
        telemetryRentGMPercent: '',
        microbulkFill: '',
        microbulkFillGM: '',
        microbulkFillGMPercent: '',
        cylinderMaintenanceCharge: '',
        cylinderMaintenanceChargeGM: '',
        cylinderMaintenanceChargeGMPercent: '',
        hazmatCharge: '',
        hazmatChargeGM: '',
        hazmatChargeGMPercent: '',
        miscellaneousCharge: '',
        miscellaneousChargeGM: '',
        miscellaneousChargeGMPercent: '',
        total: '',
        totalGM: '',
        totalGMPercent: '',
      });
      setSelectedLineItems([]);
      setButtonActionText('Submit');
    }, 1000);

    console.log('Added invoice:', addedInvoice);
  };
  const calculateGMPercent = (lineItem) => {
    const lineItemTotal = formData[lineItem];
    const lineItemTotalGM = formData[`${lineItem}GM`];
    if (
      lineItemTotal === '' ||
      lineItemTotal === 0 ||
      lineItemTotalGM === '' ||
      lineItemTotalGM === 0
    ) {
      return 0;
    }

    const lineItemGMPercent = (lineItemTotalGM / lineItemTotal) * 100;

    return lineItemGMPercent;
  };
  const calculateTotal = () => {
    const {
      deliveryCharges,
      microbulkRent,
      installLabor,
      installParts,
      telemetryRent,
      microbulkFill,
      cylinderMaintenanceCharge,
      hazmatCharge,
      miscellaneousCharge,
    } = formData;

    // Convert the field values to numbers and sum them up
    const total =
      Number(deliveryCharges) +
      Number(microbulkRent) +
      Number(installLabor) +
      Number(installParts) +
      Number(telemetryRent) +
      Number(microbulkFill) +
      Number(cylinderMaintenanceCharge) +
      Number(hazmatCharge) +
      Number(miscellaneousCharge);

    return total;
  };
  const calculateTotalGM = () => {
    const {
      deliveryChargesGM,
      microbulkRentGM,
      installLaborGM,
      installPartsGM,
      telemetryRentGM,
      microbulkFillGM,
      cylinderMaintenanceChargeGM,
      hazmatChargeGM,
      miscellaneousChargeGM,
    } = formData;

    // Convert the field values to numbers and sum them up
    const totalGM =
      Number(deliveryChargesGM) +
      Number(microbulkRentGM) +
      Number(installLaborGM) +
      Number(installPartsGM) +
      Number(telemetryRentGM) +
      Number(microbulkFillGM) +
      Number(cylinderMaintenanceChargeGM) +
      Number(hazmatChargeGM) +
      Number(miscellaneousChargeGM);

    return totalGM;
  };
  const calculateTotalGMPercentage = () => {
    const total = calculateTotal();
    const totalGM = calculateTotalGM();
    console.log(total, totalGM);
    if (totalGM === '' || totalGM === 0 || total === '' || total === 0) {
      return 0;
    }

    const totalGMPercentage = (totalGM / total) * 100;

    return totalGMPercentage;
  };
  const LineItemSelect = () => {
    return (
      <div className="w-full">
        <label
          htmlFor="lineItem"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Line Item
        </label>
        {lineItems.map((item, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="checkbox"
              id={`lineItem-${index}`}
              value={item}
              checked={selectedLineItems.includes(item)}
              onChange={handleLineItemChange}
              className="mr-2"
            />
            <label
              htmlFor={`lineItem-${index}`}
              className="text-sm font-medium text-gray-900 dark:text-white"
            >
              {item}
            </label>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div
      id="add-invoice-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex items-center justify-center"
    >
      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-8 max-w-3xl w-full">
        {/* Modal content */}
        <button
          ref={closeButtonRef}
          type="button"
          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          data-modal-hide="add-invoice-modal"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
        <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
          Add invoice for {customerName}
        </h3>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label
                htmlFor="month"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Month
              </label>
              <select
                name="month"
                id="month"
                value={formData.month}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
              >
                <option value="">Select a month</option>
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="year"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Year
              </label>
              <input
                type="number"
                name="year"
                id="year"
                value={formData.year}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
              />
            </div>
            <div>
              <label
                htmlFor="invoiceNumber"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Invoice Number
              </label>
              <input
                type="text"
                name="invoiceNumber"
                id="invoiceNumber"
                value={formData.invoiceNumber}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
              />
            </div>
          </div>
          <LineItemSelect />
          {selectedLineItems.includes('Delivery') && (
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label
                  htmlFor="deliveryCharges"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Delivery Charges
                </label>
                <input
                  type="text"
                  name="deliveryCharges"
                  id="deliveryCharges"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.deliveryCharges}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="deliveryChargesGM"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Delivery Charges GM
                </label>
                <input
                  type="text"
                  name="deliveryChargesGM"
                  id="deliveryChargesGM"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.deliveryChargesGM}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="deliveryChargesGMPercent"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Delivery Charges GM Percent
                </label>
                <input
                  type="text"
                  name="deliveryChargesGMPercent"
                  id="deliveryChargesGMPercent"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={calculateGMPercent('deliveryCharges')}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            </div>
          )}
          {selectedLineItems.includes('Microbulk Rental') && (
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label
                  htmlFor="microbulkRent"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Microbulk Rent
                </label>
                <input
                  type="text"
                  name="microbulkRent"
                  id="microbulkRent"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.microbulkRent}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="microbulkRentGM"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Microbulk Rent GM
                </label>
                <input
                  type="text"
                  name="microbulkRentGM"
                  id="microbulkRentGM"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.microbulkRentGM}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="microbulkRentPercent"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Microbulk Rent Percent
                </label>
                <input
                  type="text"
                  name="microbulkRentPercent"
                  id="microbulkRentPercent"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={calculateGMPercent('microbulkRent')}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            </div>
          )}
          {selectedLineItems.includes('Install Labor') && (
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label
                  htmlFor="installLabor"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Install Labor
                </label>
                <input
                  type="text"
                  name="installLabor"
                  id="installLabor"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.installLabor}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="installLaborGM"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Install Labor GM
                </label>
                <input
                  type="text"
                  name="installLaborGM"
                  id="installLaborGM"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.installLaborGM}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="installLaborGMPercent"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Install Labor GM Percent
                </label>
                <input
                  type="text"
                  name="installLaborGMPercent"
                  id="installLaborGMPercent"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={calculateGMPercent('installLabor')}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            </div>
          )}
          {selectedLineItems.includes('Install Parts') && (
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label
                  htmlFor="installParts"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Install Parts
                </label>
                <input
                  type="text"
                  name="installParts"
                  id="installParts"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.installParts}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="installPartsGM"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Install Parts GM
                </label>
                <input
                  type="text"
                  name="installPartsGM"
                  id="installPartsGM"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.installPartsGM}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="installPartsGMPercent"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Install Parts GM Percent
                </label>
                <input
                  type="text"
                  name="installPartsGMPercent"
                  id="installPartsGMPercent"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={calculateGMPercent('installParts')}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            </div>
          )}
          {selectedLineItems.includes('Telemetry Rental') && (
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label
                  htmlFor="telemetryRent"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Telemetry Rent
                </label>
                <input
                  type="text"
                  name="telemetryRent"
                  id="telemetryRent"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.telemetryRent}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="telemetryRentGM"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Telemetry Rent GM
                </label>
                <input
                  type="text"
                  name="telemetryRentGM"
                  id="telemetryRentGM"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.telemetryRentGM}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="telemetryRentGMPercent"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Telemetry Rent GM Percent
                </label>
                <input
                  type="text"
                  name="telemetryRentGMPercent"
                  id="telemetryRentGMPercent"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={calculateGMPercent('telemetryRent')}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            </div>
          )}
          {selectedLineItems.includes('Microbulk Fill') && (
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label
                  htmlFor="microbulkFill"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Microbulk Fill
                </label>
                <input
                  type="text"
                  name="microbulkFill"
                  id="microbulkFill"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.microbulkFill}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="microbulkFillGM"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Microbulk Fill GM
                </label>
                <input
                  type="text"
                  name="microbulkFillGM"
                  id="microbulkFillGM"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.microbulkFillGM}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="microbulkFillGMPercent"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Microbulk Fill GM Percent
                </label>
                <input
                  type="text"
                  name="microbulkFillGMPercent"
                  id="microbulkFillGMPercent"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={calculateGMPercent('microbulkFill')}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            </div>
          )}
          {selectedLineItems.includes('Cylinder Maintenance') && (
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label
                  htmlFor="cylinderMaintenanceCharge"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Cylinder Maintenance Charge
                </label>
                <input
                  type="text"
                  name="cylinderMaintenanceCharge"
                  id="cylinderMaintenanceCharge"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.cylinderMaintenanceCharge}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="cylinderMaintenanceChargeGM"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Cylinder Maintenance Charge GM
                </label>
                <input
                  type="text"
                  name="cylinderMaintenanceChargeGM"
                  id="cylinderMaintenanceChargeGM"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.cylinderMaintenanceChargeGM}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="cylinderMaintenanceChargeGMPercent"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Cylinder Maintenance Charge GM Percent
                </label>
                <input
                  type="text"
                  name="cylinderMaintenanceChargeGMPercent"
                  id="cylinderMaintenanceChargeGMPercent"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={calculateGMPercent('cylinderMaintenanceCharge')}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            </div>
          )}
          {selectedLineItems.includes('Hazmat Charge') && (
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label
                  htmlFor="hazmatCharge"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Hazmat Charge
                </label>
                <input
                  type="text"
                  name="hazmatCharge"
                  id="hazmatCharge"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.hazmatCharge}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="hazmatChargeGM"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Hazmat Charge GM
                </label>
                <input
                  type="text"
                  name="hazmatChargeGM"
                  id="hazmatChargeGM"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.hazmatChargeGM}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="hazmatChargeGMPercent"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Hazmat Charge GM Percent
                </label>
                <input
                  type="text"
                  name="hazmatChargeGMPercent"
                  id="hazmatChargeGMPercent"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={calculateGMPercent('hazmatCharge')}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            </div>
          )}
          {selectedLineItems.includes('Miscellaneous') && (
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label
                  htmlFor="miscellaneousCharge"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Miscellaneous Charge
                </label>
                <input
                  type="text"
                  name="miscellaneousCharge"
                  id="miscellaneousCharge"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.miscellaneousCharge}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="miscellaneousChargeGM"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Miscellaneous Charge GM
                </label>
                <input
                  type="text"
                  name="miscellaneousChargeGM"
                  id="miscellaneousChargeGM"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={formData.miscellaneousChargeGM}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="miscellaneousChargeGMPercent"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Miscellaneous Charge GM Percent
                </label>
                <input
                  type="text"
                  name="miscellaneousChargeGMPercent"
                  id="miscellaneousChargeGMPercent"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={calculateGMPercent('miscellaneousCharge')}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            </div>
          )}

          {calculateTotal() !== 0 && (
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label
                  htmlFor="total"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Total
                </label>
                <input
                  type="text"
                  name="total"
                  id="total"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={calculateTotal()}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div>
                <label
                  htmlFor="totalGM"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Total GM
                </label>
                <input
                  type="text"
                  name="totalGM"
                  id="totalGM"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={calculateTotalGM()}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div>
                <label
                  htmlFor="totalGMPercent"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Total GM Percent
                </label>
                <input
                  type="text"
                  name="totalGMPercent"
                  id="totalGMPercent"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  value={calculateTotalGMPercentage()}
                  readOnly
                />
              </div>
            </div>
          )}
          <button
            type="submit"
            className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {buttonActionText}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddInvoiceModal;
