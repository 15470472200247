import React, { useState } from 'react';
import { addCustomer } from '../utils/api';
import { useOutletContext } from 'react-router-dom';

const AddCustomer = () => {
  const { token } = useOutletContext();
  const [customerData, setCustomerData] = useState({
    name: '',
    customer_number: '',
    contract_start: '',
    term_length: '',
    increase_notice: '',
    product: '',
    estimated_yearly_volume: '',
    equipment_installed: '',
    capex_invested_value: '',
    labor_to_install: '',
    tank_id: '',
    tank_monthly_rental_fee: '',
    telemetry_id: '',
    telemetry_monthly_rental_fee: '',
    date_installed: '',
    distance_to_location: '',
    first_fill_date: '',
    average_driver_time: '',
    roi: '',
    roi_date: '',
    roi_at_contract_end: '',
    email: '',
    contact_name: '',
    contact_phone: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Handle changes for the other inputs
    setCustomerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // If the ROI input is modified
    if (name === 'roi') {
      const monthsToAdd = parseInt(value, 10);
      if (Number.isNaN(monthsToAdd)) {
        return; // If ROI is not a valid number, exit early
      }

      const dateInstalled = new Date(customerData.date_installed); // This will give you the date_installed as a Date object

      const roiDate = new Date(dateInstalled);
      roiDate.setMonth(roiDate.getMonth() + monthsToAdd); // Add the number of months specified in the ROI input

      const formattedRoiDate = `${roiDate.getFullYear()}-${String(
        roiDate.getMonth() + 1
      ).padStart(2, '0')}-${String(roiDate.getDate()).padStart(2, '0')}`;

      // Update the roi_date in your state
      setCustomerData((prevState) => ({
        ...prevState,
        roi_date: formattedRoiDate,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Perform desired actions with customerData
    console.log(customerData);
    const addedCustomer = await addCustomer(token, customerData);
    console.log('Added customer:', addedCustomer);
    // Reset form values
    setCustomerData({
      name: '',
      customer_number: '',
      contract_start: '',
      term_length: '',
      increase_notice: '',
      product: '',
      estimated_yearly_volume: '',
      equipment_installed: '',
      capex_invested_value: '',
      labor_to_install: '',
      tank_id: '',
      tanks: [],
      telemetries: [],
      date_installed: '',
      distance_to_location: '',
      first_fill_date: '',
      average_driver_time: '',
      roi: '',
      roi_date: '',
      roi_at_contract_end: '',
      email: '',
      contact_name: '',
      contact_phone: '',
    });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="p-4 sm:ml-64">
      <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
        <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-16">
          <fieldset className="flex flex-row items-start">
            <legend className="text-2xl xl:text-3xl font-extrabold pb-2">
              Customer Information
            </legend>
            <div className="grid grid-cols-2 gap-4 w-full">
              <div className="flex flex-col items-start">
                <label htmlFor="name" className="text-lg ">
                  Name*
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={customerData.name}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                  required
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="customer_number" className="text-lg ">
                  Customer ID*
                </label>
                <input
                  type="text"
                  id="customer_number"
                  name="customer_number"
                  value={customerData.customer_number}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                  required
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="email" className="text-lg ">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={customerData.email}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="contact_name" className="text-lg ">
                  Contact Name
                </label>
                <input
                  type="text"
                  id="contact_name"
                  name="contact_name"
                  value={customerData.contact_name}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="contact_phone" className="text-lg ">
                  Contact Phone
                </label>
                <input
                  type="tel"
                  id="contact_phone"
                  name="contact_phone"
                  value={customerData.contact_phone}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="distance_to_location" className="text-lg ">
                  Distance to Location
                </label>
                <input
                  type="number"
                  id="distance_to_location"
                  name="distance_to_location"
                  value={customerData.distance_to_location}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="average_driver_time" className="text-lg ">
                  Average Driver Time
                </label>
                <input
                  type="number"
                  id="average_driver_time"
                  name="average_driver_time"
                  value={customerData.average_driver_time}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
            </div>
            <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
          </fieldset>
          <fieldset className="flex flex-row items-start">
            <legend className="text-2xl xl:text-3xl font-extrabold pb-2">
              Contract Information
            </legend>
            <div className="grid grid-cols-2 gap-4 w-full">
              <div className="flex flex-col items-start">
                <label htmlFor="contract_start" className="text-lg ">
                  Contract Start*
                </label>
                <input
                  type="date"
                  id="contract_start"
                  name="contract_start"
                  value={customerData.contract_start}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                  required
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="term_length" className="text-lg ">
                  Term Length*
                </label>
                <input
                  type="number"
                  id="term_length"
                  name="term_length"
                  value={customerData.term_length}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                  required
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="increase_notice" className="text-lg ">
                  Increase Notice*
                </label>
                <input
                  type="date"
                  id="increase_notice"
                  name="increase_notice"
                  value={customerData.increase_notice}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                  required
                />
              </div>
            </div>
            <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
          </fieldset>
          <fieldset className="flex flex-row items-start">
            <legend className="text-2xl xl:text-3xl font-extrabold pb-2">
              Product and Equipment Info
            </legend>
            <div className="grid grid-cols-2 gap-4 w-full">
              <div className="flex flex-col items-start">
                <label htmlFor="product" className="text-lg ">
                  Product*
                </label>
                <select
                  id="product"
                  name="product"
                  required
                  value={customerData.product}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                >
                  <option value="">Select Product</option>
                  <option value="Argon">Argon</option>
                  <option value="Nitrogen">Nitrogen</option>
                  <option value="Oxygen">Oxygen</option>
                  <option value="Multiple">Multiple</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="estimated_yearly_volume" className="text-lg ">
                  Estimated Yearly Volume
                </label>
                <input
                  type="number"
                  id="estimated_yearly_volume"
                  name="estimated_yearly_volume"
                  value={customerData.estimated_yearly_volume}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="equipment_installed" className="text-lg ">
                  Equipment Installed*
                </label>
                <select
                  required
                  id="equipment_installed"
                  name="equipment_installed"
                  value={customerData.equipment_installed}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                >
                  <option value="">Select Equipment</option>
                  <option value="7500L">7500L</option>
                  <option value="5500L">5500L</option>
                  <option value="3000L">3000L</option>
                  <option value="2000L">2000L</option>
                  <option value="1500L">1500L</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="labor_to_install" className="text-lg ">
                  Labor to Install
                </label>
                <input
                  type="number"
                  id="labor_to_install"
                  name="labor_to_install"
                  value={customerData.labor_to_install}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="tank_id" className="text-lg ">
                  Tank ID
                </label>
                <input
                  type="text"
                  id="tank_id"
                  name="tank_id"
                  value={customerData.tank_id}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="tank_monthly_rental_fee" className="text-lg ">
                  Tank Monthly Rental Fee
                </label>
                <input
                  type="number"
                  id="tank_monthly_rental_fee"
                  name="tank_monthly_rental_fee"
                  value={customerData.tank_monthly_rental_fee}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="telemetry_id" className="text-lg ">
                  Telemetry ID
                </label>
                <input
                  type="text"
                  id="telemetry_id"
                  name="telemetry_id"
                  value={customerData.telemetry_id}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
              <div className="flex flex-col items-start">
                <label
                  htmlFor="telemetry_monthly_rental_fee"
                  className="text-lg "
                >
                  Telemetry Monthly Rental Fee
                </label>
                <input
                  type="number"
                  id="telemetry_monthly_rental_fee"
                  name="telemetry_monthly_rental_fee"
                  value={customerData.telemetry_monthly_rental_fee}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="date_installed" className="text-lg ">
                  Date Installed*
                </label>
                <input
                  type="date"
                  required
                  id="date_installed"
                  name="date_installed"
                  value={customerData.date_installed}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="first_fill_date" className="text-lg ">
                  First Fill Date
                </label>
                <input
                  type="date"
                  id="first_fill_date"
                  name="first_fill_date"
                  value={customerData.first_fill_date}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
            </div>
          </fieldset>
          <fieldset className="flex flex-row items-start">
            <legend className="text-2xl xl:text-3xl font-extrabold pb-2">
              Investment Info
            </legend>
            <div className="grid grid-cols-2 gap-4 w-full">
              <div className="flex flex-col items-start">
                <label htmlFor="capex_invested_value" className="text-lg ">
                  Capex Invested Value
                </label>
                <input
                  type="number"
                  id="capex_invested_value"
                  name="capex_invested_value"
                  value={customerData.capex_invested_value}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>

              <div className="flex flex-col items-start">
                <label htmlFor="roi" className="text-lg ">
                  ROI (Return on Investment)
                </label>
                <input
                  type="number"
                  id="roi"
                  name="roi"
                  value={customerData.roi}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="roi_date" className="text-lg ">
                  ROI Date
                </label>
                <input
                  type="date"
                  id="roi_date"
                  name="roi_date"
                  value={customerData.roi_date}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
              <div className="flex flex-col items-start">
                <label htmlFor="roi_at_contract_end" className="text-lg ">
                  ROI at Contract End
                </label>
                <input
                  type="number"
                  id="roi_at_contract_end"
                  name="roi_at_contract_end"
                  value={customerData.roi_at_contract_end}
                  onChange={handleChange}
                  className="w-full border-2 border-gray-300 rounded p-2"
                />
              </div>
            </div>
          </fieldset>
          <div className="">
            <button
              type="submit"
              className="bg-gray-800 text-white  py-2 px-4 rounded"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCustomer;
