import React from 'react';
import { formatAsDollarAmount, truncateDecimals } from '../utils/utils';

const ViewInvoiceModal = ({ invoice }) => {
  const renderTableRow = (label, value, date) => {
    if (value === null || value === 0) {
      return null;
    }
    return (
      <tr>
        <td className="text-white">{label}:</td>
        <td className="text-lg font-semibold text-right">
          {date && value}
          {!date && formatAsDollarAmount(value)}
        </td>
      </tr>
    );
  };
  function formatDate(month, year) {
    const date = new Date(year, month - 1);
    const monthString = date.toLocaleString('en-US', { month: 'long' });
    const formattedDate = `${monthString}, ${year}`;
    return formattedDate;
  }
  return (
    <div
      id="view-invoice-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="text-white fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex items-center justify-center"
    >
      <div className="relative rounded-lg shadow bg-gray-700 p-8 max-w-3xl w-full w-[75%]">
        {/* Modal content */}
        <button
          type="button"
          className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          data-modal-hide="view-invoice-modal"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close modal</span>
        </button>

        <h2 className="text-2xl font-semibold mb-4">Invoice Information</h2>
        <table className="w-full border-separate border-spacing-2 border border-slate-500">
          <tbody>
            {renderTableRow(
              'Invoice Number',
              '#' + invoice.invoiceNumber,
              true
            )}
            {renderTableRow(
              'Date',
              formatDate(invoice.month, invoice.year),
              true
            )}
            {renderTableRow('Delivery Charges', invoice.deliveryCharges)}
            {renderTableRow('Delivery Charges GM', invoice.deliveryChargesGM)}
            {renderTableRow(
              'Delivery Charges GM Percent',
              invoice.deliveryChargesGMPercent
            )}
            {renderTableRow('Microbulk Rent', invoice.microbulkRent)}
            {renderTableRow('Microbulk Rent GM', invoice.microbulkRentGM)}
            {renderTableRow(
              'Microbulk Rent GM Percent',
              invoice.microbulkRentGMPercent
            )}
            {renderTableRow('Install Labor', invoice.installLabor)}
            {renderTableRow('Install Labor GM', invoice.installLaborGM)}
            {renderTableRow(
              'Install Labor GM Percent',
              invoice.installLaborGMPercent
            )}
            {renderTableRow('Install Parts', invoice.installParts)}
            {renderTableRow('Install Parts GM', invoice.installPartsGM)}
            {renderTableRow(
              'Install Parts GM Percent',
              invoice.installPartsGMPercent
            )}
            {renderTableRow('Telemetry Rent', invoice.telemetryRent)}
            {renderTableRow('Telemetry Rent GM', invoice.telemetryRentGM)}
            {renderTableRow(
              'Telemetry Rent GM Percent',
              invoice.telemetryRentGMPercent
            )}
            {renderTableRow('Microbulk Fill', invoice.microbulkFill)}
            {renderTableRow('Microbulk Fill GM', invoice.microbulkFillGM)}
            {renderTableRow(
              'Microbulk Fill GM Percent',
              invoice.microbulkFillGMPercent
            )}
            {renderTableRow(
              'Cylinder Maintenance Charge',
              invoice.cylinderMaintenanceCharge
            )}
            {renderTableRow(
              'Cylinder Maintenance Charge GM',
              invoice.cylinderMaintenanceChargeGM
            )}
            {renderTableRow(
              'Cylinder Maintenance Charge GM Percent',
              invoice.cylinderMaintenanceChargeGMPercent
            )}
            {renderTableRow('Hazmat Charge', invoice.hazmatCharge)}
            {renderTableRow('Hazmat Charge GM', invoice.hazmatChargeGM)}
            {renderTableRow(
              'Hazmat Charge GM Percent',
              invoice.hazmatChargeGMPercent
            )}
            {renderTableRow(
              'Miscellaneous Charge',
              invoice.miscellaneousCharge
            )}
            {renderTableRow(
              'Miscellaneous Charge GM',
              invoice.miscellaneousChargeGM
            )}
            {renderTableRow(
              'Miscellaneous Charge GM Percent',
              invoice.miscellaneousChargeGMPercent
            )}
            {renderTableRow('Total', invoice.total)}
            {renderTableRow('Total GM', invoice.totalGM)}
            {renderTableRow('Total GM Percent', invoice.totalGMPercent)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewInvoiceModal;
