import React, { useState, useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { getCustomerById } from '../utils/api';
import { formatAsDollarAmount, truncateDecimals } from '../utils/utils';
import { Accordion } from 'flowbite';
import AddInvoiceModal from './AddInvoiceModal';
import ViewInvoiceModal from './ViewInvoiceModal';
import EditCustomerModal from './EditCustomerModal';
const CustomerDetail = () => {
  const { token } = useOutletContext();
  const { id } = useParams();
  const [fetched, setFetched] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [activeAccordion, setActiveAccordion] = useState('');
  const [customer, setCustomer] = useState({
    name: '',
    customer_number: '',
    contract_start: '',
    term_length: '',
    increase_notice: '',
    product: '',
    estimated_yearly_volume: '',
    equipment_installed: '',
    capex_invested_value: '',
    labor_to_install: '',
    tank_id: '',
    tank_monthly_rental_fee: '',
    telemetry_id: '',
    telemetry_monthly_rental_fee: '',
    date_installed: '',
    distance_to_location: '',
    first_fill_date: '',
    average_driver_time: '',
    roi: '',
    roi_date: '',
    roi_at_contract_end: '',
    email: '',
    contact_name: '',
    contact_phone: '',
    invoices: [],
    year_totals: {},
  });

  const fetchCustomer = async () => {
    try {
      const customer = await getCustomerById(token, id);
      console.log(customer);
      setCustomer(customer);
      setFetched(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCustomer();
  }, [id, token]);

  if (!customer) {
    return <div>Loading customer details...</div>;
  }

  const customerInformation = {
    title: 'Customer Information',
    dataPoints: [
      { field: 'Name', value: customer.name },
      { field: 'Customer Number', value: customer.customer_number },
      {
        field: 'Contract Start',
        value: new Date(customer.contract_start).toLocaleDateString(),
      },
      { field: 'Term Length', value: customer.term_length },
      {
        field: 'Increase Notice',
        value: new Date(customer.increase_notice).toLocaleDateString(),
      },
    ],
  };
  const contractInformation = {
    title: 'Contract Information',
    dataPoints: [
      {
        field: 'Contract Start',
        value: new Date(customer.contract_start).toLocaleDateString(),
      },
      { field: 'Term Length', value: customer.term_length },
      {
        field: 'Increase Notice',
        value: new Date(customer.increase_notice).toLocaleDateString(),
      },
    ],
  };
  const productEquipmentInformation = {
    title: 'Product and Equipment Information',
    dataPoints: [
      { field: 'Product', value: customer.product },
      {
        field: 'Estimated Yearly Volume',
        value: customer.estimated_yearly_volume,
      },
      { field: 'Equipment Installed', value: customer.equipment_installed },
      { field: 'Tank ID', value: customer.tank_id },
      {
        field: 'Tank Monthly Rental Fee',
        value: customer.tank_monthly_rental_fee,
      },
      { field: 'Telemetry ID', value: customer.telemetry_id },
      {
        field: 'Telemetry Monthly Rental Fee',
        value: customer.telemetry_monthly_rental_fee,
      },
      { field: 'Labor to Install', value: customer.labor_to_install },
      {
        field: 'Date Installed',
        value: new Date(customer.date_installed).toLocaleDateString(),
      },
      {
        field: 'First Fill Date',
        value: new Date(customer.first_fill_date).toLocaleDateString(),
      },
    ],
  };
  const investmentInformation = {
    title: 'Investment Information',
    dataPoints: [
      { field: 'Capex Invested Value', value: customer.capex_invested_value },
      { field: 'ROI', value: customer.roi },
      {
        field: 'ROI Date',
        value: new Date(customer.roi_date).toLocaleDateString(),
      },
      { field: 'ROI at Contract End', value: customer.roi_at_contract_end },
    ],
  };

  const handleAccordionClick = (accordionId) => {
    setActiveAccordion((prevAccordion) =>
      prevAccordion === accordionId ? '' : accordionId
    );
  };
  console.log('Here: ', customer);
  function formatDate(month, year) {
    const date = new Date(year, month - 1);
    const monthString = date.toLocaleString('en-US', { month: 'long' });
    const formattedDate = `${monthString}, ${year}`;
    return formattedDate;
  }
  return (
    <div className="p-4 sm:ml-64">
      <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
          <div className="flex flex-col items-center justify-center h-24 rounded bg-gray-50 dark:bg-gray-800">
            <h5 className="text-2xl text-white font-bold pb-2">
              Customer Name
            </h5>
            <p className="text-lg text-white">{customer.name}</p>
          </div>
          <div className="flex flex-col items-center justify-center h-24 rounded bg-gray-50 dark:bg-gray-800">
            <p className="text-2xl text-white font-bold pb-2">
              Customer Contact
            </p>
            <p className="text-lg text-white">{customer.contact_name}</p>
          </div>
          <div className="flex flex-col items-center justify-center h-24 rounded bg-gray-50 dark:bg-gray-800">
            <p className="text-2xl text-white font-bold pb-2">Customer Email</p>
            <p className="text-lg text-white">{customer.email}</p>
          </div>
        </div>
        {fetched && (
          <div className="grid grid-cols-1 gap-4">
            <div className="flex items-center justify-center mb-4 rounded bg-gray-50 dark:bg-gray-800">
              <div
                id="accordion-collapse"
                data-accordion="collapse"
                className="w-full"
              >
                <h2 id="accordion-collapse-heading-1">
                  <button
                    type="button"
                    className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                    data-accordion-target="#accordion-collapse-body-1"
                    aria-expanded={activeAccordion === 'accordion-1'}
                    aria-controls="accordion-collapse-body-1"
                    onClick={() => handleAccordionClick('accordion-1')}
                  >
                    <span className="text-md text-white font-extrabold">
                      Customer Information
                    </span>
                    <svg
                      data-accordion-icon
                      className="w-6 h-6 shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="accordion-collapse-body-1"
                  className="hidden"
                  aria-labelledby="accordion-collapse-heading-1"
                >
                  <table className="min-w-full divide-y divide-gray-200 border-[3px] border-solid border-gray-800">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Field
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Value
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {customerInformation.dataPoints.map(
                        (dataPoint, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {dataPoint.field}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {dataPoint.value}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <h2 id="accordion-collapse-heading-2">
                  <button
                    type="button"
                    className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                    data-accordion-target="#accordion-collapse-body-2"
                    aria-expanded="false"
                    aria-controls="accordion-collapse-body-2"
                  >
                    <span className="text-md text-white font-extrabold">
                      Contract Information
                    </span>
                    <svg
                      data-accordion-icon
                      className="w-6 h-6 shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="accordion-collapse-body-2"
                  className="hidden"
                  aria-labelledby="accordion-collapse-heading-2"
                >
                  <table className="min-w-full divide-y divide-gray-200 border-[3px] border-solid border-gray-800">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Field
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Value
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {contractInformation.dataPoints.map(
                        (dataPoint, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {dataPoint.field}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {dataPoint.value}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <h2 id="accordion-collapse-heading-3">
                  <button
                    type="button"
                    className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                    data-accordion-target="#accordion-collapse-body-3"
                    aria-expanded="false"
                    aria-controls="accordion-collapse-body-3"
                  >
                    <span className="text-md text-white font-extrabold">
                      Product and Equipment Info:
                    </span>
                    <svg
                      data-accordion-icon
                      className="w-6 h-6 shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="accordion-collapse-body-3"
                  className="hidden"
                  aria-labelledby="accordion-collapse-heading-3"
                >
                  <table className="min-w-full divide-y divide-gray-200 border-[3px] border-solid border-gray-800">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Field
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Value
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {productEquipmentInformation.dataPoints.map(
                        (dataPoint, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {dataPoint.field}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {dataPoint.value}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <h2 id="accordion-collapse-heading-4">
                  <button
                    type="button"
                    className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200  dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                    data-accordion-target="#accordion-collapse-body-4"
                    aria-expanded="false"
                    aria-controls="accordion-collapse-body-4"
                  >
                    <span className="text-md text-white font-extrabold">
                      Investment Info
                    </span>
                    <svg
                      data-accordion-icon
                      className="w-6 h-6 shrink-0"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </h2>
                <div
                  id="accordion-collapse-body-4"
                  className="hidden"
                  aria-labelledby="accordion-collapse-heading-4"
                >
                  <table className="min-w-full divide-y divide-gray-200 border-[3px] border-solid border-gray-800">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Field
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Value
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {investmentInformation.dataPoints.map(
                        (dataPoint, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {dataPoint.field}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {dataPoint.value}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="w-full flex justify-center mt-3 mb-3">
                  <div
                    data-modal-target="edit-customer-modal"
                    data-modal-toggle="edit-customer-modal"
                    className="cursor-pointer inline-flex items-center justify-center px-2 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300"
                    type="button"
                  >
                    Edit Customer
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col items-center justify-center mb-4 rounded bg-gray-50 dark:bg-gray-800">
          <h1 className="text-2xl text-white mt-2 mb-2 font-extrabold text-center">
            Invoices
          </h1>
          {customer.invoices.length > 0 && (
            <div className="p-4 border-2 bg-gray-800 border-gray-200 border-dashed rounded-lg dark:border-gray-700 w-full">
              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {customer.invoices.map((item, index) => (
                  <li className="full-w pt-2 pb-2" key={index}>
                    <div className="flex items-center justify-between">
                      <div className="flex-1 min-w-0 mx-2">
                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                          {formatDate(item.month, item.year)}
                        </p>
                      </div>
                      <div className="flex-1 min-w-0 mx-2">
                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                          Invoice Number: {item.invoiceNumber}
                        </p>
                      </div>
                      <div className="flex-1 min-w-0 mx-2">
                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                          Invoice Total: {formatAsDollarAmount(item.total)}
                        </p>
                      </div>

                      <div
                        className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white hover:bg-gray-700 p-2 rounded-lg cursor-pointer"
                        onClick={() => {
                          setInvoice(item);
                        }}
                        data-modal-target="view-invoice-modal"
                        data-modal-toggle="view-invoice-modal"
                      >
                        View Details
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="w-full flex justify-center mt-3 mb-3">
            <div
              data-modal-target="add-invoice-modal"
              data-modal-toggle="add-invoice-modal"
              className="cursor-pointer inline-flex items-center justify-center px-2 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300"
              type="button"
            >
              Add Invoice
            </div>
          </div>
        </div>

        <AddInvoiceModal
          fetchCustomer={fetchCustomer}
          customerId={customer._id}
          customerName={customer.name}
        />
        <ViewInvoiceModal invoice={invoice} />
        {fetched && (
          <EditCustomerModal
            customer={customer}
            token={token}
            fetchCustomer={fetchCustomer}
          />
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
          {Object.entries(customer.year_totals).length > 0 ? (
            Object.entries(customer.year_totals).map(([year, total]) => (
              <div
                key={year}
                className="flex flex-col items-center justify-center h-24 rounded bg-gray-50 dark:bg-gray-800"
              >
                <p className="text-2xl text-white font-bold pb-2">
                  {year} Invoice Total
                </p>
                <p className="text-md text-white">
                  {formatAsDollarAmount(total)}
                </p>
              </div>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center h-24 rounded bg-gray-50 dark:bg-gray-800">
              <p className="text-xl text-white font-extrabold">
                No year totals available
              </p>
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
            <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
          </div>
          <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
            <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
          </div>
          <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
            <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
          </div>
          <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
            <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetail;
