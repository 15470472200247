import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';
import App from './App.jsx';
import Login from './screens/Login.jsx';
import Register from './screens/Register.jsx';
import Activate from './screens/Activate.jsx';
import Private from './screens/Private.jsx';
import Admin from './screens/Admin.jsx';
import ForgetPassword from './screens/ForgetPassword.jsx';
import ResetPassword from './screens/ResetPassword.jsx';

import PrivateRoute from './Routes/PrivateRoute';
import AdminRoute from './Routes/AdminRoute';
import 'react-toastify/dist/ReactToastify.css';
import UserHome from './screens/UserHome.jsx';
import AllCustomers from './components/AllCustomers.jsx';
import AddCustomer from './components/AddCustomer.jsx';
import './index.css';
import DashboardContent from './components/DashboardContent.jsx';
import CustomerDetail from './components/CustomerDetail.jsx';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" exact element={<Login />} />
      <Route path="/login" exact element={<Login />} />

      <Route path="/register" exact element={<Register />} />
      <Route path="/users/password/forget" exact element={<ForgetPassword />} />
      <Route
        path="/users/password/reset/:token"
        exact
        element={<ResetPassword />}
      />
      <Route path="/users/activate/:token" exact element={<Activate />} />
      <Route path="/home" element={<PrivateRoute />}>
        <Route path="" element={<UserHome />}>
          <Route path="/home/dashboard" element={<DashboardContent />} />
          <Route path="/home/all-customers" element={<AllCustomers />} />
          <Route path="/home/add-customer" element={<AddCustomer />} />
          <Route path="/home/customer/:id" element={<CustomerDetail />} />
        </Route>
      </Route>

      {/* <AdminRoute path="/admin" exact component={Admin} /> */}
      <Route path="/" render={() => <Navigate to="/" />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
